var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('list-view',{key:_vm.listViewKey,attrs:{"headers":_vm.headers,"url":_vm.url,"loading":_vm.loading,"items-per-page":1000,"enable-search":false,"enable-filters":false,"sort-by":"order_id","sort-desc":"","footer-props":{ showFirstLastPage: true, 'items-per-page-options': [1000] },"group-by":"order_id"},on:{"data":_vm.onData},scopedSlots:_vm._u([{key:"toolbar-actions-append",fn:function(){return [_c('v-row',{staticClass:"d-inline-flex",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":_vm.mode == 1 ? 'blue' : undefined},on:{"click":function($event){return _vm.setMode(1)}}},on),[_vm._v(" Not ordered ("+_vm._s(_vm.modeCount[1])+") ")])]}}])},[_c('span',[_vm._v(" Supplier orders not ordered (Recent orders that are created in past 7 days are ignored) ")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":_vm.mode == 2 ? 'blue' : undefined},on:{"click":function($event){return _vm.setMode(2)}}},on),[_vm._v(" Not submitted to Medius ("+_vm._s(_vm.modeCount[2])+") ")])]}}])},[_c('span',[_vm._v(" Purchase orders not submitted to Medius, or not saved properly on Medius (Recent orders that are created in past 7 days are ignored)")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":_vm.mode == 3 ? 'blue' : undefined},on:{"click":function($event){return _vm.setMode(3)}}},on),[_vm._v(" Matched pending ("+_vm._s(_vm.modeCount[3])+") ")])]}}])},[_c('span',[_vm._v(" Purchase order is matched with supplier's invoice but status is stucked in between or error is thrown (Recent orders that are created in past 7 days are ignored)")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":_vm.mode == 4 ? 'blue' : undefined},on:{"click":function($event){return _vm.setMode(4)}}},on),[_vm._v(" Not matched for 30 days ("+_vm._s(_vm.modeCount[4])+") ")])]}}])},[_c('span',[_vm._v(" Purchase orders are submitted, but supplier's invoice is not matched for more than 30 days after submission ")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":_vm.mode == 5 ? 'blue' : undefined},on:{"click":function($event){return _vm.setMode(5)}}},on),[_vm._v(" Not invoiced ("+_vm._s(_vm.modeCount[5])+") ")])]}}])},[_c('span',[_vm._v(" No invoice is created or kredinor not submitted after order is matched (Recent orders that are created in past 7 days and admin orders are ignored)")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":_vm.mode == 6 ? 'blue' : undefined},on:{"click":function($event){return _vm.setMode(6)}}},on),[_vm._v(" Others ("+_vm._s(_vm.modeCount[6])+") ")])]}}])},[_c('span',[_vm._v(" Pending orders that have other reasons (Recent orders that are created in past 7 days are ignored)")])])],1)],1)]},proxy:true},{key:"group.header",fn:function(ref){
var items = ref.items;
var group = ref.group;
var headers = ref.headers;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_vm._t("group.header",function(){return [_c('th',{attrs:{"colspan":headers.length}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'fa-chevron-up' : 'fa-chevron-down'))]),_c('span',[_vm._v(" Order ID: ")]),_c('router-link',{attrs:{"to":_vm.appendSitePrefix('/' + group + '/order2/main'),"target":"_blank"}},[_vm._v(" "+_vm._s(group)+" ")])],1)]},{"items":items,"group":group,"headers":headers,"isOpen":isOpen,"toggle":toggle})]}},{key:"item.eontyre_quantity",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(item.eontyre_quantity))])]}},{key:"item.medius_quantity",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(item.medius_quantity > 0 ? item.medius_quantity : '-'))])]}},{key:"item.cost_exchanged",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.formatPrice(item.cost_exchanged, 'NOK')))])]}},{key:"item.invoice_number",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.invoice_number || '-'))])]}},{key:"item.medius_invoice_number",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.medius_invoice_number || '-'))])]}},{key:"item.supplier_order_status_text",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.supplier_order_status_text === 'Ordered' ? 'green' : 'red',"text-color":"white"}},[_vm._v(_vm._s(item.supplier_order_status_text))])]}},{key:"item.medius_status_text",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.medius_status === 10 ? 'green' : item.medius_status < 10 ? 'blue' : 'red',"text-color":"white"}},[_vm._v(" "+_vm._s(item.medius_status_text)+" ")])]}},{key:"item.submit_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.submit_status == 1 ? 'green' : 'red',"text-color":"white"}},[_vm._v(_vm._s(item.submit_status == 1 ? 'Submitted' : item.submit_status == 9 ? 'Error' : 'No'))])]}},{key:"item.webshop",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.webshop || '-')+" ")]}},{key:"item._created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTimeString(item._created))+" ")]}},{key:"item._updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTimeString(item._updated))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }