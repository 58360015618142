




































































































































































// op.order_id,
// ms.medius_supplier_id,
// op.product_name,
// op.number AS eontyre_quantity,
// mol.medius_invoice_item_quantity AS medius_quantity,
// op.cost_exchanged,
// ps.name AS supplier_name,
// mol.medius_status,
// sol.status AS supplier_order_status,
// i.invoice_number,
// mol.medius_invoice_number,
// fssl.submit_status,
// c.name AS customer_name,
// c.customer_number,
// sol._created,
// sol._updated
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ListView from '@/components/list/ListView.vue'
import { appendSitePrefix } from '@/utils/routeUtils'
import { formatDateTimeString } from '@/utils/dateUtils'

@Component({
  components: { ListView },
})
export default class ListPendingOrders extends Vue {
  private appendSitePrefix = appendSitePrefix
  private formatDateTimeString = formatDateTimeString

  private listViewKey = 0
  private loading = false
  private mode = 1
  private modeCount = {}

  private headers = []

  private created(): void {
    this.loading = true
    this.setInitialHeaders()
  }

  private get url(): string {
    return '/v4/site/order/reports/medius/pending?mode=' + this.mode
  }

  private setMode(mode): void {
    this.mode = mode
  }

  private onData(data): void {
    this.modeCount = data?.responseData?.modeCount || {}
  }

  private formatPrice(value, currency) {
    const formatter = new Intl.NumberFormat('sv-SE', {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'code',
      useGrouping: true,
      maximumFractionDigits: 2,
    })
    return formatter.format(value)
  }

  private setInitialHeaders() {
    this.headers = [
      {
        text: 'Order ID',
        value: 'order_id',
      },
      {
        text: 'Customer No.',
        value: 'customer_number',
        sortable: false,
      },
      {
        text: 'Customer Name',
        value: 'customer_name',
        sortable: false,
      },
      {
        text: 'Supplier',
        value: 'supplier_name',
        sortable: false,
      },
      {
        text: 'Product',
        value: 'product_name',
        sortable: false,
      },
      {
        text: 'Eontyre Qty',
        value: 'eontyre_quantity',
        sortable: false,
      },
      {
        text: 'Medius Qty',
        value: 'medius_quantity',
        sortable: false,
      },
      {
        text: 'Eontyre $',
        value: 'cost_exchanged',
        sortable: false,
      },
      {
        text: 'Eontyre Invoice No.',
        value: 'invoice_number',
        sortable: false,
      },
      {
        text: 'Medius Invoice No.',
        value: 'medius_invoice_number',
        sortable: false,
      },
      {
        text: 'Kredinor',
        value: 'submit_status',
        sortable: false,
      },
      {
        text: 'Medius Status',
        value: 'medius_status_text',
        sortable: false,
      },
      {
        text: 'Supplier Order ID',
        value: 'supplier_order_id',
        sortable: false,
      },
      {
        text: 'Supplier Order Status',
        value: 'supplier_order_status_text',
        sortable: false,
      },
      {
        text: 'Eontyre Order Reference',
        value: 'order_reference',
        sortable: false,
      },
      {
        text: 'Webshop',
        value: 'webshop',
        sortable: false,
      },
      {
        text: 'Supplier Order Create',
        value: '_created',
        sortable: false,
      },
      {
        text: 'Last Medius Update',
        value: '_updated',
        sortable: false,
      },
    ]
  }
}
